<template>
  <div class="theory-list">
    <div class="theory-list-header" style="padding-top: 20px">
      状态
      <el-select v-model="stateValue" style="width: 200px;margin-left: 10px" placeholder="请选择" @change="selectState">
        <el-option
            v-for="item in stateList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
    <el-table class="customTable" :data="studentList"
              style="width: 100%; margin-top: 20px; flex: 1;border: 1px solid #EEEEEE;" height="1%" size="medium"
              :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
              :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
<!--      <el-table-column v-if="role === 4" prop="username" align="center" label="学生账号"></el-table-column>-->
<!--      <el-table-column v-else prop="username" align="center" label="学生名字"></el-table-column>-->
      <el-table-column prop="exam_name" align="left" label="考试名称"></el-table-column>
      <el-table-column prop="module_name" align="center" label="考试模块"></el-table-column>
      <el-table-column prop="status" align="center" label="状态"></el-table-column>
      <el-table-column prop="begin_time" align="center" label="考试时间"></el-table-column>
      <el-table-column align="center" :label="`评分（最高分数为${maxScore}分）`">
        <template slot-scope="scope">
          <el-input-number v-if="scope.row.status !== '未提交'" v-model="scope.row.score" style="width: 88px;margin-right: 16px" :max="maxScore" :controls="false" :precision="1" ></el-input-number>
          <el-link type="warning" v-if="scope.row.status === '未批改'" @click="correctGrade(scope.row)" :underline="false">批改</el-link>
          <el-link type="warning" v-if="scope.row.status === '已批改'" @click="correctGrade(scope.row)" :underline="false">重新批改</el-link>
          <span v-if="scope.row.status === '未提交'">该学生暂未提交无法评分</span>
        </template>
      </el-table-column>
      <el-table-column prop="" align="center" label="操作">
        <template slot-scope="scope">
            <el-link v-if="scope.row.status !== '未提交'" type="primary" :underline="false"
                     class="view-detail" @click="lookDetail(scope.row.file_path)">查看详情
            </el-link>
            <el-link type="primary" :underline="false" class="view-detail" @click="lookStandard(scope.row)">
              评分标准
            </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                   :current-page="listPages.currentPageNum"
                   :page-size="listPages.eachPageNum"
                   :total="listPages.total"
                   layout="prev, pager, next, jumper"
                   @current-change="listCurrentChange">
    </el-pagination>
    <el-dialog title="评分标准"
               :visible.sync="gradingDialog" width="830px"
               custom-class="green-dialog goods-score-dialog">
      <div class="upload-file-content">
        <el-table :data="gradingList" class="customTable goods-text-table" style="width: 100%; flex: 1;"
                  height="1%"
                  :header-cell-style="{fontWeight: 'normal', height: '40px', color: '#333', background: '#F2F3FA',fontSize: '14px'}"
                  :cell-style="{fontSize: '14px',color: '#333333',height: '40px'}">
          <el-table-column prop="index" label="序号" align="center"></el-table-column>
          <el-table-column prop="content" label="考核内容" align="left"></el-table-column>
          <el-table-column prop="require" label="考核要点" align="left"></el-table-column>
          <el-table-column prop="standard" label="评分标准" align="left"></el-table-column>
          <el-table-column prop="score" label="配分" align="center"></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {sactionList, sactionMark} from '@/utils/apis'
export default {
  name: "Index",
  data(){
    return {
      role:Number(localStorage.getItem('role')) || '',
      studentList:[],
      listPages:{
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      maxScore:0,
      examId:this.$route.query.examId || '',
      stateValue:2,
      stateList:[
        {
          value:1,
          label:'已批改'
        },
        {
          value:2,
          label:'未批改'
        },
        {
          value:3,
          label:'未提交'
        },
      ],
      gradingDialog:false,
      gradingList:[],
    }
  },
  mounted() {
    this.getStudentList();
  },
  methods:{
    getStudentList(){
      let params = {
        exam_id:this.examId,
        type:this.stateValue,
        page:this.listPages.currentPageNum,
        limit:this.listPages.eachPageNum
      }
      sactionList(params).then((res)=>{
        this.studentList = res.data.list;
        this.listPages.total = res.data.total;
        this.maxScore = res.data.score;
        this.gradingList = res.data.scoring_criteria
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 批改&重新批改
    correctGrade(row){
      let formData = new FormData();
      formData.append('student_id', row.user_id);
      formData.append('exam_id', this.examId);
      formData.append('score',row.score);
      if(row.module_type === 12){
        formData.append('type', 1)
      } else if(row.module_type === 13){
        formData.append('type', 2)
      } else {
        formData.append('type', 3)
      }
      sactionMark(formData).then((res)=>{
        this.$message.success(res.msg);
        this.getStudentList()
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 评分标准
    lookStandard(row){
      this.gradingDialog = true;
    },
    // 查看详情
    lookDetail(file){
      window.open(file,'_blank')
    },
    selectState(){
      this.listPages.currentPageNum = 1;
      this.getStudentList()
    },
    listCurrentChange(val){
      this.listPages.currentPageNum = val;
      this.getStudentList();
    },
  }
}
</script>

<style scoped lang="scss">
.theory-list{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .theory-list-header{
    display: flex;
    align-items: center;
    span{
      line-height: 1;
    }
  }
}
</style>